import "./assets/styles/index.scss";
import Router from "./router";
import PageDown from "./views/common/pageDown";
import store from "./store";
import { Encrypt } from "./modules";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
store.subscribe(() => {
  if (process.env.REACT_APP_PERSITENT_STORAGE == "true") {
    sessionStorage.setItem("_store", Encrypt(store.getState(), "local"));
  } else {
    store.getState();
  }
});

export default function App() {
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleTabClosing);
    };
  });

  const handleTabClosing = () => {};

  const alertUser = (event) => {
    window._analytics.trackEvent("Close Tab");
  };
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={true}
        pauseOnHover={true}
        limit={2}
      />
      <div className="App">
        {process.env.REACT_APP_PAGE_DOWN === "true" ? <PageDown /> : <Router />}
      </div>
    </Provider>
  );
}
